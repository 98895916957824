import StudentsFinderInterfaceClass from "../infrastructure/students-finder-interface";
import StudentValidator from "../domain/student-validator";

import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";

export default class {
	constructor() {
		this.interface = new StudentsFinderInterfaceClass();
		this.store_interface = new StoreInterfaceClass();
		this.validator = StudentValidator;

		this.entity_name = "students";
	}

	async get_students() {
		const cached_students = this._get_stored_students();
		if (cached_students) return cached_students;

		const students = await this.interface.get_students();

		students.forEach((item) => this.validator.validate(item));

		this.store_interface.set(this.entity_name, students);

		return this._get_stored_students();
	}

	_get_stored_students() {
		return this.store_interface.get(this.entity_name);
	}

	async get_students_by_gender(gender) {
		const students = await this.get_students();

		return students.filter((student) => student[gender]);
	}

	async get_students_can_do_assigment(assigment_slug) {
		if (assigment_slug == "initial_call" || assigment_slug == "return_visit")
			assigment_slug = "publisher";
		const allowed_in_assigned = "can_do_" + assigment_slug;

		const students = await this.get_students();
		return students.filter((student) => student[allowed_in_assigned]);
	}
}
