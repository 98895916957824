<template>
	<div class="full-width full-height">
		<div class="flex-auto-height-content">
			<div class="header display-flex p-b">
				<div
					class="btn btn-primary"
					v-on:click="set_companions_by_gender('man')"
				>
					Hombres
				</div>
				<div
					class="btn btn-primary m-l"
					v-on:click="set_companions_by_gender('woman')"
				>
					Mujeres
				</div>
			</div>

			<div v-if="companions_by_gender" class="content">
				<DataTable
					:items="companions_by_gender"
					:items_per_page="40"
					:fields="table_columns"
					default_sort_by="latest_assigment"
					default_sort_order="asc"
				>
					<template #cell(other)="data">
						<div v-on:click="search(data.item.student_name)">
							<Icon icon="search" class="primary-color cursor-pointer" />
						</div>
					</template>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import DataTable from "../sub_components/DataTable.vue";

import StudentsForAssigmentFinderClass from "@src/student/application/students-for-assigment-finder.js";
const StudentsForAssigmentFinder = new StudentsForAssigmentFinderClass();

import moment from "moment";

export default {
	name: "OrderedCompanions",
	components: {
		DataTable
	},
	data() {
		return {
			companions_by_gender: null,

			table_columns: [
				{
					key: "student_name",
					label: "Nombre"
				},
				{
					key: "latest_assigment",
					label: "Última asignación",
					sortable: true,
					formatter: (value, key, item) => {
						return value ? moment(value).format("YYYY-MM-DD") : null;
					}
				},
				{
					key: "latest_companion",
					label: "Último acompañante",
					sortable: true,
					formatter: (value, key, item) => {
						return value ? moment(value).format("YYYY-MM-DD") : null;
					}
				},
				{
					key: "other",
					label: "",
					class: "text-center"
				}
			]
		};
	},
	methods: {
		async set_companions_by_gender(gender) {
			this.companions_by_gender = null;
			this.companions_by_gender =
				await StudentsForAssigmentFinder.get_companions_for_assigment(gender);
		},
		search(value) {
			this.$emit("search", value);
		}
	}
};
</script>

<style lang="css" scoped></style>
