<template>
	<div class="full-width full-height relative">
		<div v-if="loading" class="full-width flex-center-content">Loading ...</div>
		<div v-else="data" class="absolute full-width full-height">
			<div class="flex-auto-height-content">
				<div class="header p-b-xxs">
					<TextInput v-model="input_filter" placeholder="Buscar" />
				</div>
				<div class="content">
					<DataTable
						:items="data"
						:items_per_page="40"
						:fields="table_columns"
						:filter="input_filter"
						:filter_fields="['assigned', 'assignment']"
						default_sort_by="date"
						default_sort_order="desc"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DataTable from "../sub_components/DataTable.vue";
import TextInput from "@sub_components/TextInput";

import VymAssgimentsHistoricalFinderClass from "@src/vym-assigment/application/vym-assgiments-historical-finder.js";
const VymAssgimentsHistoricalFinder = new VymAssgimentsHistoricalFinderClass();

import moment from "moment";

export default {
	name: "VyM",
	components: {
		DataTable,
		TextInput
	},
	props: {
		filter: String || null
	},
	data() {
		return {
			loading: true,
			data: null,
			input_filter: this.filter,

			//Table data
			table_columns: [
				{
					key: "date",
					label: "Fecha",
					sortable: true,
					formatter: (value, key, item) => {
						return value ? moment(value).format("YYYY-MM-DD") : null;
					}
				},
				{
					key: "assignment",
					label: "Assignación",
					formatter: (value, key, item) => {
						return value ? value.value : null;
					}
				},
				{
					key: "assigned",
					label: "Asignado",
					formatter: (value, key, item) => {
						return value ? value.value : null;
					}
				}
			]
		};
	},
	async mounted() {
		this.loading = true;

		try {
			this.data =
				await VymAssgimentsHistoricalFinder.get_assigments_historical();
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},
	watch: {
		filter(new_value) {
			this.input_filter = new_value;
		}
	}
};
</script>

<style lang="css" scoped>
.same-row-height {
	display: grid;
	grid-template-rows: 1fr 1fr;
}
</style>
