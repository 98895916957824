import VymAssigmentsHistoricalFinderInterfaceClass from "../infrastructure/vym-assgiments-historical-finder-interface";
import VymAssigmentHistoricalValidator from "../domain/vym-assigment-historical-validator.js";

import StudentsFinderClass from "@src/student/application/students-finder.js";

import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";
import moment from "moment";

export default class {
	constructor() {
		this.interface = new VymAssigmentsHistoricalFinderInterfaceClass();
		this.store_interface = new StoreInterfaceClass();
		this.validator = VymAssigmentHistoricalValidator;

		this.entity_name = "vym_assigments_historical";
		this.students_finder = new StudentsFinderClass();
	}

	async get_assigments_historical() {
		const cached_assigments_historical =
			this._get_stored_assigments_historical();
		if (cached_assigments_historical) return cached_assigments_historical;

		const assigments_historical =
			await this.interface.get_assigments_historical();

		assigments_historical.forEach((item) => this.validator.validate(item));

		this.store_interface.set(this.entity_name, assigments_historical);

		return this._get_stored_assigments_historical();
	}

	async get_grouped_by_assigned() {
		const assigments_historical = await this.get_assigments_historical();
		const students = await this.students_finder.get_students();

		let result = {};
		let days = {};
		assigments_historical.forEach((assigment) => {
			if (
				assigment.assigned &&
				students.find((students) => students.id == assigment.assigned.id) &&
				moment(assigment.date).isAfter(moment().subtract(6, "months"))
			) {
				const assigned_id = assigment.assigned.id;
				const assigment_day = moment(assigment.date).format("YYYYMMDD");

				if (!result[assigned_id]) {
					result[assigned_id] = {
						assigned_data: assigment.assigned,
						by_day: {
							[assigment_day]: assigment.assignment
						}
					};
				} else {
					if (result[assigned_id].by_day[assigment_day]) {
						result[assigned_id].by_day[assigment_day] = "too_many_assigments";
					} else {
						result[assigned_id].by_day[assigment_day] = assigment.assignment;
					}
				}

				days[assigment_day] = true;
			}
		});

		return {
			available_days: Object.keys(days),
			data: result
		};
	}

	_get_stored_assigments_historical() {
		return this.store_interface.get(this.entity_name);
	}

	async get_assigments_historical_by_assigned_ids(assigned_ids) {
		const assigments_historical = await this.get_assigments_historical();

		return assigments_historical.filter(
			(assigment) =>
				assigment.assigned &&
				assigned_ids.find((item) => item == assigment.assigned.id)
		);
	}
}
