<template lang="html">
	<div class="relative">
		<input
			:type="is_password ? 'password' : 'text'"
			class="text-input form-control"
			v-model="input_value"
			:placeholder="placeholder"
		/>
		<div
			v-if="clean_button"
			class="text-input-remove grey2-color cursor-pointer"
			v-on:click="clean_input_value"
		>
			<Icon icon="times" />
		</div>
	</div>
</template>

<script>
export default {
	name: "TextInput",
	props: {
		value: String,
		placeholder: String,
		clean_button: Boolean,
		is_password: Boolean
	},
	data() {
		return {
			input_value: this.value
		};
	},
	methods: {
		emit_input_value() {
			this.$emit("input", this.input_value);
		},
		clean_input_value() {
			this.input_value = null;
			this.emit_input_value();
			this.$emit("value_cleaned");
		}
	},
	watch: {
		input_value() {
			this.emit_input_value();
		},
		value(new_value) {
			if (new_value != this.input_value) this.input_value = new_value;
		}
	}
};
</script>

<style lang="css" scoped>
.text-input {
	padding-right: 25px;
}
.text-input-remove {
	position: absolute;
	right: 10px;
	top: -2px;
}
</style>
