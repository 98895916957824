<template>
	<div class="flex-auto-height-content">
		<div class="content">
			<div class="display-flex-space-between full-height">
				<VymAssigmentsHistorical :filter="filter_value" class="p-r" />
				<VymPreferentialAssigned v-on:search="change_filter" class="p-l" />
			</div>
		</div>
		<div class="footer p-t">
			<VymGroupByAssigned />
		</div>
	</div>
</template>

<script>
import VymAssigmentsHistorical from "./VymAssigmentsHistorical";
import VymGroupByAssigned from "./VymGroupByAssigned";
import VymPreferentialAssigned from "./VymPreferentialAssigned";

export default {
	name: "VyM",
	components: {
		VymAssigmentsHistorical,
		VymGroupByAssigned,
		VymPreferentialAssigned
	},
	data() {
		return {
			filter_value: null
		};
	},
	methods: {
		change_filter(new_filter_value) {
			this.filter_value = new_filter_value;
		}
	}
};
</script>

<style lang="css" scoped>
.same-row-height {
	display: grid;
	grid-template-rows: 1fr 1fr;
}
</style>
