<template>
	<div class="full-width full-height">
		<DataTable
			v-if="assigned_latest_dates"
			:items="assigned_latest_dates"
			:items_per_page="40"
			:fields="table_columns"
			default_sort_by="latest_assigment"
			default_sort_order="asc"
		>
			<template #cell(other)="data">
				<div>
					<div v-on:click="search(data.item.student_name)">
						<Icon icon="search" class="primary-color cursor-pointer" />
					</div>
				</div>
			</template>
		</DataTable>
	</div>
</template>

<script>
import DataTable from "../sub_components/DataTable.vue";

import StudentsForAssigmentFinderClass from "@src/student/application/students-for-assigment-finder.js";
const StudentsForAssigmentFinder = new StudentsForAssigmentFinderClass();

import moment from "moment";

export default {
	name: "NeededReading",
	components: {
		DataTable
	},
	props: {
		assigment_slug: String
	},
	data() {
		return {
			loading: false,
			error: null,
			assigned_latest_dates: null,

			table_columns: [
				{
					key: "student_name",
					label: "Nombre"
				},
				{
					key: "latest_assigment",
					label: "Última asignación",
					sortable: true,
					formatter: (value, key, item) => {
						return value ? moment(value).format("YYYY-MM-DD") : null;
					}
				},
				{
					key: "latest_selected_assigment",
					label: "Última asignación seleccionada",
					sortable: true,
					formatter: (value, key, item) => {
						return value ? moment(value).format("YYYY-MM-DD") : null;
					}
				},
				{
					key: "other",
					label: ""
				}
			]
		};
	},
	async mounted() {
		this.set_assigned_latest_dates(this.assigment_slug);
	},
	methods: {
		async set_assigned_latest_dates(assigment_slug) {
			this.loading = true;

			try {
				const result =
					await StudentsForAssigmentFinder.get_students_for_assigment(
						assigment_slug
					);

				this.assigned_latest_dates = result;
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		},
		search(value) {
			this.$emit("search", value);
		}
	},
	watch: {
		assigment_slug(new_value) {
			this.set_assigned_latest_dates(new_value);
		}
	}
};
</script>

<style lang="css" scoped></style>
