export default class {
	get_assigments() {
		return [
			{
				id: 1,
				value: "Lectura",
				slug: "reading"
			},
			{
				id: 2,
				value: "Primera conversación",
				slug: "initial_call"
			},
			{
				id: 3,
				value: "Revisita",
				slug: "return_visit"
			},
			/*{
				id: 4,
				value: "Segunda revisita",
				slug: "reading"
			},*/
			{
				id: 5,
				value: "Curso bíblico",
				slug: "bible_study"
			},
			{
				id: 6,
				value: "Discurso",
				slug: "talk"
			}
		];
	}

	get_assigment_by_slug(slug) {
		const assigments = this.get_assigments();

		return assigments.find((item) => item.slug == slug);
	}
}
