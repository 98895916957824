<template lang="html">
	<span
		v-if="is_custom_icon"
		v-bind:class="'icon-' + icon"
		v-bind:style="custom_icon_style"
	></span>
	<FontAwesomeIcon v-else :icon="icon" v-bind:style="custom_icon_style" />
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
	faRefresh,
	faSearch,
	faSignOut,
	faCaretUp,
	faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import { faChartBar } from "@fortawesome/free-regular-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faRefresh, faSearch, faSignOut, faCaretUp, faCaretDown);

const custom_icons = [
	"alerts",
	"add",
	"alert",
	"close",
	"chevron-down",
	"chevron-left",
	"chevron-right",
	"chevron-up",
	"download",
	"edit",
	"eye",
	"file",
	"graph",
	"layers",
	"location",
	"lupa",
	"settings",
	"table",
	"trash",
	"energy-tower-up-arrow",
	"solar-panel-down-arrow",
	"solar-panel-solid",
	"inverter",
	"grid-tower",
	"router",
	"icon-alerts",
	"notifications"
];

export default {
	name: "Icon",
	props: {
		icon: String,
		size: [String, Number]
	},
	components: {
		FontAwesomeIcon
	},
	data() {
		const custom_icon_size_in_px = this.size || 22;

		return {
			custom_icon_style: {
				"font-size": custom_icon_size_in_px + "px"
			}
		};
	},
	computed: {
		is_custom_icon() {
			return custom_icons.indexOf(this.icon) != -1;
		}
	}
};
</script>

<style lang="css">
[class^="icon-"]:before,
[class*=" icon-"]:before,
[data-icon]:before {
	line-height: inherit !important;
}
</style>
