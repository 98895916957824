<template>
	<div class="flex-auto-height-content">
		<div class="header p-b">
			<div class="display-flex">
				<div
					v-for="(item, index) in assigments"
					class="btn btn-primary"
					v-bind:class="{ 'm-l': index > 0 }"
					v-on:click="set_assigned_latest_dates(item.slug)"
				>
					{{ item.value }}
				</div>
			</div>
		</div>

		<div class="content relative">
			<div class="absolute full-width full-height">
				<DataTable
					v-if="assigned_latest_dates && assigned_latest_dates.length > 0"
					:items="assigned_latest_dates"
					:items_per_page="20"
					:fields="table_columns"
					default_sort_by="latest_selected_assigment"
					default_sort_order="asc"
				>
					<template #cell(other)="data">
						<div v-on:click="search(data.item.assigned_name)">
							<Icon icon="search" class="primary-color cursor-pointer" />
						</div>
					</template>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import DataTable from "../sub_components/DataTable.vue";

import moment from "moment";
import VymAssigmentsFinderClass from "@src/vym-assigment/application/vym-assigments-finder";
import VymPreferentialAssignedFinderClass from "@src/vym-assigment/application/vym-preferential-assigned-finder";

const VymAssigmentsFinder = new VymAssigmentsFinderClass();
const VymPreferentialAssignedFinder = new VymPreferentialAssignedFinderClass();

export default {
	name: "VymPreferentialAssigned",
	components: {
		DataTable
	},
	data() {
		return {
			assigments: VymAssigmentsFinder.get_assigments(),
			selected_assigment_slug: null,
			loading: false,
			error: null,
			assigned_latest_dates: null,

			table_columns: [
				{
					key: "assigned_name",
					label: "Nombre"
				},
				{
					key: "latest_selected_assigment",
					label: "Última asignación seleccionada",
					sortable: true,
					formatter: (value, key, item) => {
						return value ? moment(value).format("YYYY-MM-DD") : null;
					}
				},
				{
					key: "latest_assigment",
					label: "Última asignación",
					sortable: true,
					formatter: (value, key, item) => {
						return value ? moment(value).format("YYYY-MM-DD") : null;
					}
				},
				{
					key: "other",
					label: "",
					class: "text-center"
				}
			]
		};
	},
	methods: {
		async set_assigned_latest_dates(assigment_slug) {
			this.loading = true;
			this.selected_assigment_slug = assigment_slug;

			try {
				const result =
					await VymPreferentialAssignedFinder.get_vym_preferential_assigned(
						assigment_slug
					);

				this.assigned_latest_dates = result;
			} catch (error) {
				this.error = error;
			} finally {
				this.loading = false;
			}
		},
		search(value) {
			this.$emit("search", value);
		}
	}
};
</script>
