class ValidationError {
	constructor(field, message, validated_data = null, extra_data = null) {
		this.type = "validation";
		this.field = field;
		this.message = message;
		this.validated_data = validated_data;
		if (extra_data) {
			if (extra_data.field_limit) this.field_limit = extra_data.field_limit;
			if (extra_data.property_path)
				this.property_path = extra_data.property_path;
			if (extra_data.received_value)
				this.received_value = extra_data.received_value;
			if (extra_data.valid_values) this.valid_values = extra_data.valid_values;
			if (extra_data.multilingual_field_path)
				this.multilingual_field_path = extra_data.multilingual_field_path;
		}
	}
}
module.exports = ValidationError;
