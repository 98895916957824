import RestInterface from "./rest-interface";
import EncryptInterfaceClass from "./encrypt-interface";
import StoreInterfaceClass from "./store-interface";

import {
	ddbb_encrypted_token,
	encryptation_passphrase_store_entity
} from "@config";

export default class BackendInterface {
	constructor() {
		this.rest_interface = new RestInterface();
		this.backend_url = "https://api.baserow.io";
		this.encrypt_interface = new EncryptInterfaceClass();
		this.store_interface = new StoreInterfaceClass();
	}

	async get_students() {
		const url =
			this.backend_url +
			"/api/database/rows/table/114933/?user_field_names=true";

		const result = await this._rest_method("get", url);

		return result.results;
	}

	async get_assigments_historical() {
		const url =
			this.backend_url +
			"/api/database/rows/table/114948/?user_field_names=true";

		return await this._get_data_from_url_with_bucle(url);
	}

	async get_vym_assigments_historical() {
		const url =
			this.backend_url +
			"/api/database/rows/table/128734/?user_field_names=true";

		return await this._get_data_from_url_with_bucle(url);
	}

	async _get_data_from_url_with_bucle(url) {
		let result = await this._rest_method("get", url);
		let data = result.results;

		if (result.next) {
			let continue_bucle = true;
			let next_url = result.next;

			while (continue_bucle) {
				next_url = next_url.replace("http://", "https://");
				result = await this._rest_method("get", next_url);

				data = data.concat(result.results);

				next_url = result.next;
				if (!next_url) continue_bucle = false;
			}
		}

		return data;
	}

	async _rest_method(method_name, url, args = null) {
		let params = {};

		if (args && args.body_params) params.body_params = args.body_params;
		if (args && args.query_params) params.query_params = args.query_params;
		if (args && args.files) params.files = args.files;

		const user_token = this._get_user_token();
		if (user_token) {
			params.headers = {
				Authorization: user_token
			};
		}

		try {
			const result = await this.rest_interface[method_name](url, params);

			return result;
		} catch (error) {
			//const reported_error = this._check_rest_error(error);

			throw error;
		}
	}

	_get_user_token() {
		const encryptation_passphrase = this.store_interface.get(
			encryptation_passphrase_store_entity
		);

		const decrypted_token = this.encrypt_interface.decript_string(
			encryptation_passphrase,
			ddbb_encrypted_token
		);

		return "Token " + decrypted_token;
	}
}
