import AssigmentsHistoricalFinderInterfaceClass from "../infrastructure/assigments-historical-finder-interface";
import AssigmentHistoricalValidator from "../domain/assigment-historical-validator";

import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";

export default class {
	constructor() {
		this.interface = new AssigmentsHistoricalFinderInterfaceClass();
		this.store_interface = new StoreInterfaceClass();
		this.validator = AssigmentHistoricalValidator;

		this.entity_name = "assigments_historical";
	}

	async get_assigments_historical() {
		const cached_assigments_historical =
			this._get_stored_assigments_historical();
		if (cached_assigments_historical) return cached_assigments_historical;

		const assigments_historical =
			await this.interface.get_assigments_historical();

		assigments_historical.forEach((item) => this.validator.validate(item));

		this.store_interface.set(this.entity_name, assigments_historical);

		return this._get_stored_assigments_historical();
	}

	async get_assigments_historical_by_students_ids(students_ids) {
		const assigments_historical = await this.get_assigments_historical();

		return assigments_historical.filter(
			(assigment) =>
				assigment.assigned &&
				students_ids.find((item) => item == assigment.assigned.id)
		);
	}

	async get_companions_historical_by_students_ids(students_ids) {
		const companions_historical = await this.get_assigments_historical();

		return companions_historical.filter(
			(assigment) =>
				assigment.companion &&
				students_ids.find((item) => item == assigment.companion.id)
		);
	}

	_get_stored_assigments_historical() {
		return this.store_interface.get(this.entity_name);
	}
}
