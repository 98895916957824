<template>
	<div class="alert" :class="'alert-' + alert_type">
		<span>{{ transformed_message }}</span>
	</div>
</template>

<script>
import AppError from "@src/shared/domain/errors/app-error.js";

export default {
	name: "Alert",
	props: ["type", "message", "validation_error"],

	computed: {
		transformed_message() {
			return this.message instanceof AppError
				? this.message.user_message
				: "Ha habido un error";
		},
		alert_type() {
			return this.type ? this.type : "danger";
		}
	}
};
</script>
