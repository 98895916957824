<template>
	<div class="full-width full-height">
		<div
			class="flex-center-content full-width full-height"
			v-if="!is_encryptation_passphrase_setted"
		>
			<div id="enter_passphrase_box" class="box-shadow p-sm">
				<div class="font-bold font-l">Contraseña:</div>
				<TextInput v-model="encryptation_passphrase" :is_password="true" />
				<div class="m-t flex-horizontal-right">
					<button
						class="btn btn-primary"
						v-on:click="set_encryptation_passphrase(encryptation_passphrase)"
					>
						Guardar
					</button>
				</div>
			</div>
		</div>
		<div v-else class="flex-auto-height-content p-sm" style="max-height: 100%">
			<div class="header p-b-xs border-b m-b">
				<div class="display-flex-space-between">
					<div class="display-flex">
						<router-link :to="{ name: 'assigments-historical' }">
							<div class="btn btn-primary">Estudiantiles</div>
						</router-link>
						<router-link :to="{ name: 'vym' }">
							<div class="btn btn-primary m-l">VyM</div>
						</router-link>
					</div>

					<div class="display-flex">
						<div class="btn btn-primary" v-on:click="force_refresh">
							<Icon icon="refresh" />
						</div>

						<div class="btn btn-danger m-l" v-on:click="logout">
							<Icon icon="fa-sign-out" />
						</div>
					</div>
				</div>
			</div>

			<div class="content">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
import TextInput from "@sub_components/TextInput";

import AppServiceClass from "@src/shared/application/app-service.js";
import EncryptationPassphraseServiceClass from "@src/encryptation-passphrase/application/encryptation-passphrase-service";

const AppService = new AppServiceClass();
const EncryptationPassphraseService = new EncryptationPassphraseServiceClass();

export default {
	name: "App",
	components: {
		TextInput
	},
	data() {
		return {
			is_encryptation_passphrase_setted: false,
			encryptation_passphrase: null
		};
	},
	mounted() {
		const encryptation_passphrase =
			EncryptationPassphraseService.get_encryptation_passphrase();

		if (encryptation_passphrase) this.is_encryptation_passphrase_setted = true;
		else this.logout();
	},
	methods: {
		set_encryptation_passphrase(encryptation_passphrase) {
			EncryptationPassphraseService.set_encryptation_passphrase(
				encryptation_passphrase
			);
			this.is_encryptation_passphrase_setted = true;
			this.encryptation_passphrase = null;
		},
		force_refresh() {
			AppService.restore_store();
			//Reload window
			this.$router.go();
			//window.location.reload();
		},
		logout() {
			AppService.restore_store();
			this.is_encryptation_passphrase_setted = false;
		}
	}
};
</script>

<style lang="css" scoped>
#enter_passphrase_box {
	width: 300px;
}
</style>
