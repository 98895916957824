<template>
	<div class="full-width full-height">
		<div v-if="loading">
			<Loader />
		</div>
		<div v-else-if="error">
			<Alert :message="error" />
		</div>
		<div v-else-if="assigments" class="flex-auto-height-content">
			<div class="header p-b-xxs">
				<TextInput v-model="input_filter" placeholder="Buscar" />
			</div>
			<div class="content">
				<DataTable
					:items="assigments"
					:items_per_page="40"
					:fields="table_columns"
					:filter="input_filter"
					:filter_fields="['assigned', 'companion']"
					default_sort_by="date"
					default_sort_order="desc"
				>
					<template #cell(confirmed)="data">
						<input type="checkbox" v-model="data.value" />
					</template>

					<template #cell(study)="data">
						<div class="max-width-string">
							<span>{{ data.value }}</span>
						</div>
					</template>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import DataTable from "../sub_components/DataTable.vue";
import TextInput from "@sub_components/TextInput";

import AssigmentsHistoricalClass from "@src/assigment/application/assigments-historical-finder.js";
const AssigmentsHistorical = new AssigmentsHistoricalClass();

import moment from "moment";

export default {
	name: "AssigmentsHistorical",
	components: {
		DataTable,
		TextInput
	},
	props: {
		filter: String || null
	},
	data() {
		return {
			loading: false,
			error: null,
			assigments: null,
			input_filter: this.filter,

			//Table data
			table_columns: [
				{
					key: "date",
					label: "Fecha",
					sortable: true,
					formatter: (value, key, item) => {
						return value ? moment(value).format("YYYY-MM-DD") : null;
					}
				},
				{
					key: "assigned",
					label: "Asignado",
					formatter: (value, key, item) => {
						return value ? value.value : null;
					}
				},
				{
					key: "assignment",
					label: "Assignación",
					formatter: (value, key, item) => {
						return value ? value.value : null;
					}
				},
				{
					key: "circumstances",
					label: "Circunstancias",
					formatter: (value, key, item) => {
						return value ? value : null;
					}
				},
				{
					key: "companion",
					label: "Acompañante",
					formatter: (value, key, item) => {
						return value ? value.value : null;
					}
				}
				/*{
					key: "study",
					label: "Lección",
					formatter: (value, key, item) => {
						return value ? value.value : null;
					}
				}*/
			]
		};
	},
	async mounted() {
		this.loading = true;

		try {
			this.assigments = await AssigmentsHistorical.get_assigments_historical();
		} catch (error) {
			this.error = error;
		} finally {
			this.loading = false;
		}
	},
	watch: {
		filter(new_value) {
			this.input_filter = new_value;
		}
	}
};
</script>

<style lang="css" scoped>
.max-width-string {
	white-space: nowrap;
	width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
