import DBInterface from "@src/shared/infrastructure/ddbb-interface";

export default class {
	constructor() {
		this.db_interface = new DBInterface();
	}

	async get_students() {
		const db_data = await this.db_interface.get_students();

		const available_students = db_data.filter(
			(student) => !student["Deshabilitado"]
		);

		return available_students.map((item) => this._convert_db_student(item));
	}

	_convert_db_student(db_student) {
		return {
			id: db_student.id,
			full_name: db_student["Nombre completo"],
			name: db_student["Nombre"],
			last_name: db_student["Apellido"],
			//Can do student assigments
			can_do_reading: db_student["Lectura"],
			can_do_publisher: db_student["Publicadora"],
			can_do_companion: db_student["Acompañante"],
			can_do_bible_study: db_student["Curso biblico"],
			can_do_talk: db_student["Discurso"],
			//can do vym assigments
			can_do_presidency: db_student["Presidencia"],
			can_do_treasures: db_student["Tesoros"],
			can_do_pearls: db_student["Perlas"],
			can_do_better_teachers: db_student["Mejores maestros"],
			can_do_christian_life: db_student["Vida cristiana"],
			can_do_book: db_student["Libro"],
			can_do_book_reading: db_student["Lectura libro"],
			can_do_prayers: db_student["Oraciones"],

			man: db_student["Hombre"],
			woman: db_student["Mujer"],
			comments: db_student["Comentarios"]
		};
	}
}
