<template>
	<div class="full-width full-height">
		<div v-if="loading" class="full-width flex-center-content">Loading ...</div>
		<div v-else-if="data">
			<div>
				<table class="full-width">
					<thead>
						<tr>
							<th></th>
							<th v-for="day in data.available_days">
								{{ format_day(day) }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="row in data.data" class="calendar-table-row">
							<td>{{ row.assigned_data.value }}</td>
							<td v-for="day in data.available_days" class="text-center">
								<div
									v-if="row.by_day[day] && row.by_day[day].id"
									class="calendar-table-element"
									:class="get_css_class_by_assigment(row.by_day[day].id)"
								>
									{{ row.by_day[day].value.substring(0, 1) }}
								</div>
								<div
									v-else-if="row.by_day[day]"
									class="calendar-table-element has_error"
								>
									E
								</div>
								<div v-else>-</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import VymAssgimentsHistoricalFinderClass from "@src/vym-assigment/application/vym-assgiments-historical-finder.js";
const VymAssgimentsHistoricalFinder = new VymAssgimentsHistoricalFinderClass();

import moment from "moment";

export default {
	name: "VymGroupByAssigned",
	data() {
		return {
			loading: true,
			data: null
		};
	},
	async mounted() {
		this.loading = true;

		try {
			this.data = await VymAssgimentsHistoricalFinder.get_grouped_by_assigned();

			console.log(this.data);
		} catch (error) {
		} finally {
			this.loading = false;
		}
	},
	methods: {
		format_day(day) {
			return moment(day).format("DD/MM");
		},
		get_css_class_by_assigment(assigment_id) {
			let result;
			switch (assigment_id) {
				case 1: //presidency
				case 2: //treasures
				case 4: //christian_life
				case 5: //book
				case 8: //better_teachers
					result = "important";
					break;
				case "too_many_assigments":
					result = "error";
					break;
				default:
					result = "not_important";
					break;
			}
			return result;
		}
	}
};
</script>

<style lang="css" scoped>
.table-row {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
}
.calendar-table-row:hover {
	background-color: rgb(223, 223, 223);
}
.calendar-table-element  {
	width: 100%;
	height: 100%;
}
.important {
	background-color: rgb(156, 156, 247);
}
.has_error {
	background-color: red;
}
.not_important {
	background-color: #bbffbb;
}
</style>
