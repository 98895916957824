<template>
	<div id="grid" class="full-width full-height">
		<AssigmentsHistorical :filter="filter_value" />

		<div>
			<div class="half-height flex-auto-height-content">
				<div class="header p-b">
					<div class="display-flex">
						<div
							class="btn btn-primary"
							v-on:click="assigment_slug = 'reading'"
						>
							Lectura
						</div>
						<div
							class="btn btn-primary m-l"
							v-on:click="assigment_slug = 'initial_call'"
						>
							Primera con.
						</div>
						<div
							class="btn btn-primary m-l"
							v-on:click="assigment_slug = 'return_visit'"
						>
							Revisita
						</div>
						<div
							class="btn btn-primary m-l"
							v-on:click="assigment_slug = 'bible_study'"
						>
							Curso bíblico
						</div>
						<div
							class="btn btn-primary m-l"
							v-on:click="assigment_slug = 'talk'"
						>
							Discurso
						</div>
					</div>
				</div>

				<div class="content" v-if="assigment_slug">
					<StudentForAssigment
						:assigment_slug="assigment_slug"
						v-on:search="change_filter"
					/>
				</div>
			</div>

			<hr />

			<div class="half-height">
				<OrderedCompanions v-on:search="change_filter" />
			</div>
		</div>
	</div>
</template>

<script>
import AssigmentsHistorical from "./AssigmentsHistorical";

import StudentForAssigment from "./StudentForAssigment";
import OrderedCompanions from "./OrderedCompanions";

export default {
	name: "Historical",
	components: {
		AssigmentsHistorical,
		StudentForAssigment,
		OrderedCompanions
	},
	data() {
		return {
			assigment_slug: null,
			filter_value: null
		};
	},
	methods: {
		change_filter(new_filter_value) {
			this.filter_value = new_filter_value;
		}
	}
};
</script>

<style lang="css" scoped>
#grid {
	display: flex;
	justify-content: space-between;
}

#grid > div:first-child {
	width: 1000px;
}

#grid > div:last-child {
	width: calc(100% - 1015px);
}

.half-height {
	height: 50%;
}
</style>
