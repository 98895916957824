import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/custom-bootstrap.css";

import CustomIcon from "@sub_components/CustomIcon";
import Loader from "@sub_components/Loader";
import Alert from "@sub_components/Alert";

createApp(App)
	.use(router)
	.use(BootstrapVue)
	.component("Icon", CustomIcon)
	.component("Loader", Loader)
	.component("Alert", Alert)
	.mount("#app");
