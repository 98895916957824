<template>
	<div class="flex-auto-height-content">
		<div class="content">
			<div id="custom-data-table">
				<table>
					<thead role="rowgroup">
						<tr role="row">
							<th
								v-for="(field, index) in fields"
								:class="{ 'cursor-pointer': field.sortable }"
								v-on:click="field.sortable ? set_sort_order(field.key) : null"
								role="columnheader"
								scope="col"
								:aria-colindex="index + 1"
							>
								<div v-if="head_has_custom_content(field.key)">
									<slot :name="'head(' + field.key + ')'" />
								</div>
								<div v-else>
									<div>
										<span v-if="field.label">
											{{ field.label }}
										</span>
									</div>
									<div
										v-if="field.sortable"
										class="data-table-sort full-height"
									>
										<div class="flex-center-content full-height">
											<div>
												<div
													class="flex-center-content"
													:class="
														sort_by &&
														sort_by == field.key &&
														sort_order == 'asc'
															? 'grey1-color'
															: 'grey2-color'
													"
												>
													<Icon icon="caret-up" size="16" />
												</div>
												<div
													class="flex-center-content"
													:class="
														sort_by &&
														sort_by == field.key &&
														sort_order == 'desc'
															? 'grey1-color'
															: 'grey2-color'
													"
												>
													<Icon icon="caret-down" size="16" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</th>
						</tr>
					</thead>

					<tbody role="rowgroup">
						<tr
							v-for="(item, item_index) in items_to_show"
							role="row"
							:aria-rowindex="item_index + 1"
						>
							<td
								v-for="(field, index) in fields"
								role="cell"
								:aria-colindex="index + 1"
							>
								<div v-if="cell_has_custom_content(field.key)">
									<slot
										:name="'cell(' + field.key + ')'"
										v-bind="{
											value: get_object_value_from_path(item, field.key),
											item: item
										}"
									/>
								</div>

								<div v-else :class="field.class || null">
									<span>
										{{ get_field_path_formatted_value(item, field.key) }}
									</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div
			class="footer"
			v-if="items_per_page && items_to_show && items_per_page < items.length"
		>
			<div id="data-table-paginator" class="flex-center-content p-t-xs p-b-xs">
				<div
					class="flex-center-content"
					v-bind:class="{ 'cursor-pointer': current_page != 1 }"
					v-on:click="
						() => {
							if (current_page != 1) current_page = 1;
						}
					"
				>
					<span><<</span>
				</div>
				<div
					class="flex-center-content"
					v-bind:class="{ 'cursor-pointer': current_page != 1 }"
					v-on:click="
						() => {
							if (current_page != 1) current_page = current_page - 1;
						}
					"
				>
					<span><</span>
				</div>

				<div
					class="flex-center-content"
					v-if="current_page > paginator_max_links + 1"
				>
					<span>...</span>
				</div>

				<div
					v-if="paginator_pages_links"
					class="flex-center-content"
					:class="page == current_page ? 'primary-bg white' : 'cursor-pointer'"
					v-for="page in paginator_pages_links"
					v-on:click="
						() => {
							if (page != current_page) current_page = page;
						}
					"
				>
					<span>{{ page }}</span>
				</div>

				<div
					class="flex-center-content"
					v-if="total_pages - current_page > paginator_max_links"
				>
					<span>...</span>
				</div>

				<div
					class="flex-center-content"
					v-bind:class="{ 'cursor-pointer': current_page != total_pages }"
					v-on:click="
						() => {
							if (current_page != total_pages) current_page = current_page + 1;
						}
					"
				>
					<span>></span>
				</div>
				<div
					class="flex-center-content"
					v-bind:class="{ 'cursor-pointer': current_page != total_pages }"
					v-on:click="
						() => {
							if (current_page != total_pages) current_page = total_pages;
						}
					"
				>
					<span>>></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "DataTable",
	props: {
		items: Array,
		items_per_page: Number,
		fields: Array, //[{ key: String, label: String, class: String, formatter: (value, key, item) => { return value; } }]
		default_sort_by: String,
		default_sort_order: String, //asc | desc
		filter: String,
		filter_fields: Array
	},
	data() {
		return {
			sort_by: this.default_sort_by,
			sort_order: this.default_sort_order,
			current_page: 1,
			paginator_max_links: 2
		};
	},
	methods: {
		get_object_value_from_path(object, path) {
			var paths = path.split("."),
				current = object,
				i;

			for (i = 0; i < paths.length; ++i) {
				if (current[paths[i]] == undefined) {
					return undefined;
				} else {
					current = current[paths[i]];
				}
			}
			return current;
		},
		is_number(number_to_check) {
			return typeof number_to_check == "number";
		},

		is_string(string_to_check) {
			return typeof string_to_check == "string";
		},
		head_has_custom_content(field_path) {
			return this.$slots.hasOwnProperty("head(" + field_path + ")");
		},
		cell_has_custom_content(field_path) {
			return this.$slots.hasOwnProperty("cell(" + field_path + ")");
		},
		get_field_path_formatted_value(item, field_path) {
			const field_path_data = this.fields.find(
				(element) => element.key == field_path
			);
			const item_value = this.get_object_value_from_path(item, field_path);
			return field_path_data.formatter
				? field_path_data.formatter(item_value, item)
				: item_value;
		},
		set_sort_order(sort_by) {
			if (this.sort_by != sort_by) {
				this.sort_by = sort_by;
				this.sort_order = "asc";
			} else {
				this.sort_order = this.sort_order == "asc" ? "desc" : "asc";
			}

			this.$emit("sort_changed");
		}
	},
	computed: {
		filtered_items() {
			if (!this.filter || !this.filter_fields) return this.items;

			const filter_lower_case = this.filter.toLowerCase();

			return this.items.filter((item) => {
				for (let index in this.filter_fields) {
					const field = this.filter_fields[index];

					const value = this.get_field_path_formatted_value(item, field);
					if (!value || value == "") return false;
					const value_lower_case = value.toLowerCase();

					if (value_lower_case.search(filter_lower_case) != -1) return true;
				}

				return false;
			});
		},
		sorted_items() {
			if (!this.filtered_items) return null;
			if (!this.sort_by || !this.sort_order) return this.filtered_items;

			const sort_by = this.sort_by;
			const order = this.sort_order;

			const item_with_sort_by_element = this.filtered_items.find((item) => {
				const value = this.get_object_value_from_path(item, sort_by);
				return value != null && value != undefined;
			});
			if (item_with_sort_by_element == null) return this.filtered_items;

			const sorted_field_first_element = this.get_field_path_formatted_value(
				item_with_sort_by_element,
				sort_by
			);

			if (this.is_number(sorted_field_first_element)) {
				if (order == "asc") {
					return this.filtered_items.sort(
						(a, b) =>
							parseFloat(this.get_field_path_formatted_value(a, sort_by)) -
							parseFloat(this.get_field_path_formatted_value(b, sort_by))
					);
				} else if (order == "desc") {
					return this.filtered_items.sort(
						(b, a) =>
							parseFloat(this.get_field_path_formatted_value(a, sort_by)) -
							parseFloat(this.get_field_path_formatted_value(b, sort_by))
					);
				}
			} else if (this.is_string(sorted_field_first_element)) {
				if (order == "asc") {
					return this.filtered_items.sort((a, b) =>
						this.get_field_path_formatted_value(a, sort_by) >
						this.get_field_path_formatted_value(b, sort_by)
							? 1
							: this.get_field_path_formatted_value(b, sort_by) >
							  this.get_field_path_formatted_value(a, sort_by)
							? -1
							: 0
					);
				} else if (order == "desc") {
					return this.filtered_items.sort((b, a) =>
						this.get_field_path_formatted_value(a, sort_by) >
						this.get_field_path_formatted_value(b, sort_by)
							? 1
							: this.get_field_path_formatted_value(b, sort_by) >
							  this.get_field_path_formatted_value(a, sort_by)
							? -1
							: 0
					);
				}
			} else {
				return this.filtered_items;
			}
		},
		items_to_show() {
			let sorted_items = this.sorted_items;

			if (this.items_per_page && this.sorted_items) {
				return sorted_items.slice(
					(this.current_page - 1) * this.items_per_page,
					this.current_page * this.items_per_page
				);
			}

			return sorted_items;
		},
		total_pages() {
			if (!this.sorted_items || !this.items_per_page) return 0;

			return Math.ceil(this.sorted_items.length / this.items_per_page);
		},
		paginator_pages_links() {
			if (!this.total_pages) return null;

			let result = [];

			if (this.current_page != 1) {
				for (
					let index = this.current_page - this.paginator_max_links;
					index < this.current_page;
					index++
				) {
					if (index > 0) result.push(index);
				}
			}

			result.push(this.current_page);

			if (this.current_page != this.total_pages) {
				for (
					let index = this.current_page + 1;
					index <= this.current_page + this.paginator_max_links;
					index++
				) {
					if (index <= this.total_pages) {
						result.push(index);
					}
				}
			}

			return result;
		}
	},
	watch: {
		filter() {
			this.current_page = 1;
			this.$emit("filtered");
		}
	}
};
</script>

<style scoped>
.data-table-sort {
	position: absolute;
	right: 0;
	top: 0;
}
.data-table-sort > div > div > div {
	height: 6px;
}
#custom-data-table {
	max-height: 100%;
	margin: 0;
}
#custom-data-table table {
	border-collapse: collapse;
	margin-bottom: 0;
	width: 100%;
}
#custom-data-table table thead th {
	padding: 0.75rem;
	position: sticky !important;
	top: 0;
	background-color: #ffffff;
	border-bottom: 2px solid #dee2e6;
}
#custom-data-table table tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.05);
}
#custom-data-table table tbody tr:hover {
	color: #212529;
	background-color: rgba(0, 0, 0, 0.075);
}
#custom-data-table table td {
	padding: 0.75rem;
}

#data-table-paginator > div {
	padding: 0.5rem 0.75rem;
	border: 1px solid #dee2e6;
	width: 2rem;
	height: 2rem;
}
#data-table-paginator > div:first-child {
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}
#data-table-paginator > div:last-child {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}
</style>
