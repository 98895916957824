<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script></script>

<style>
@import url("https://fonts.googleapis.com/css?family=Montserrat:500,600,700,800,900&display=swap");
@import "assets/general.css";
@import "assets/style.css";
#app {
	/*font-family: "Avenir", Helvetica, Arial, sans-serif;*/
	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body,
#app,
#app {
	height: 100%;
	margin: 0;
	overflow: hidden;
	background-color: #f7f7f7;
}
</style>
