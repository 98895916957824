const CryptoJS = require("crypto-js");

export default class {
	constructor() {
		this.encriptation_interface = CryptoJS;
	}

	encrypt_string(passphrase, text_to_encrypt) {
		return CryptoJS.AES.encrypt(text_to_encrypt, passphrase).toString();
	}

	decript_string(passphrase, text_to_decrypt) {
		const bytes = CryptoJS.AES.decrypt(text_to_decrypt, passphrase);
		const originalText = bytes.toString(CryptoJS.enc.Utf8);
		return originalText;
	}
}
