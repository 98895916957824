import DBInterface from "@src/shared/infrastructure/ddbb-interface";

export default class {
	constructor() {
		this.db_interface = new DBInterface();
	}

	async get_assigments_historical() {
		const db_data = await this.db_interface.get_assigments_historical();

		return db_data.map((item) => this._convert_db_assigment_historical(item));
	}

	_convert_db_assigment_historical(db_assigment_historical) {
		return {
			id: db_assigment_historical.id,
			name: db_assigment_historical.Name,
			date: new Date(db_assigment_historical.Fecha),
			assigned: db_assigment_historical.Asignado[0]
				? db_assigment_historical.Asignado[0]
				: null,
			study: db_assigment_historical["Lección"][0]
				? db_assigment_historical["Lección"][0]
				: null,
			companion: db_assigment_historical["Acompañante"][0]
				? db_assigment_historical["Acompañante"][0]
				: null,
			assignment: db_assigment_historical["Asignación"][0]
				? db_assigment_historical["Asignación"][0]
				: null,
			circumstances: db_assigment_historical["Circunstancias"]
				? db_assigment_historical["Circunstancias"].value
				: null
		};
	}
}
