import VymAssigmentsFinderClass from "./vym-assigments-finder";
import StudentsFinderClass from "@src/student/application/students-finder";
import VymAssigmentsHistoricalFinderClass from "@src/vym-assigment/application/vym-assgiments-historical-finder";

import moment from "moment";

export default class {
	constructor() {
		this.students_finder = new StudentsFinderClass();
		this.assigments_finder = new VymAssigmentsFinderClass();
		this.assigments_historical_finder =
			new VymAssigmentsHistoricalFinderClass();
	}

	async get_vym_preferential_assigned(assigment_slug) {
		const selected_assigment =
			this.assigments_finder.get_assigment_by_slug(assigment_slug);

		console.log(selected_assigment);

		const allowed_students =
			await this.students_finder.get_students_can_do_assigment(assigment_slug);
		const allowed_students_ids = allowed_students.map((student) => student.id);

		const allowed_students_historical_assigments =
			await this.assigments_historical_finder.get_assigments_historical_by_assigned_ids(
				allowed_students_ids
			);

		let assigments_grouped_by_assigned = {};
		allowed_students.forEach((assigned) => {
			assigments_grouped_by_assigned[assigned.id] = {
				assigned_id: assigned.id,
				assigned_name: assigned.full_name,
				latest_assigment: null,
				latest_selected_assigment: null
			};
		});

		allowed_students_historical_assigments.forEach((assigment) => {
			const assigned_id = assigment.assigned ? assigment.assigned.id : null;

			if (assigned_id) {
				if (
					assigments_grouped_by_assigned[assigned_id].latest_assigment ==
						null ||
					moment(
						assigments_grouped_by_assigned[assigned_id].latest_assigment
					).isBefore(assigment.date)
				) {
					assigments_grouped_by_assigned[assigned_id].latest_assigment =
						assigment.date;
				}

				if (
					assigment.assignment.id == selected_assigment.id &&
					(assigments_grouped_by_assigned[assigned_id]
						.latest_selected_assigment == null ||
						moment(
							assigments_grouped_by_assigned[assigned_id]
								.latest_selected_assigment
						).isBefore(assigment.date))
				)
					assigments_grouped_by_assigned[
						assigned_id
					].latest_selected_assigment = assigment.date;
			}
		});

		//Add students that never did this assigment
		allowed_students.forEach((student) => {
			if (!assigments_grouped_by_assigned[student.id])
				assigments_grouped_by_assigned[student.id] = {
					assigned_id: student.id,
					assigned_name: student.full_name,
					latest_assigment: null,
					latest_selected_assigment: null
				};
		});

		let assigments_grouped_by_assigned_as_array = [];
		for (let assigned_id in assigments_grouped_by_assigned) {
			assigments_grouped_by_assigned_as_array.push(
				assigments_grouped_by_assigned[assigned_id]
			);
		}

		return assigments_grouped_by_assigned_as_array;
	}
}
