import Joi from "joi";
import Validator from "@src/shared/domain/validator";

const schema = Joi.object({
	id: Joi.number(),
	full_name: Joi.string(),
	name: Joi.string(),
	last_name: Joi.string(),
	can_do_reading: Joi.boolean(),
	can_do_publisher: Joi.boolean(),
	can_do_companion: Joi.boolean(),
	can_do_bible_study: Joi.boolean(),
	can_do_talk: Joi.boolean(),

	can_do_presidency: Joi.boolean(),
	can_do_treasures: Joi.boolean(),
	can_do_pearls: Joi.boolean(),
	can_do_better_teachers: Joi.boolean(),
	can_do_christian_life: Joi.boolean(),
	can_do_book: Joi.boolean(),
	can_do_book_reading: Joi.boolean(),
	can_do_prayers: Joi.boolean(),

	man: Joi.boolean(),
	woman: Joi.boolean(),
	comments: Joi.string()
});

export default new Validator(schema);
