import axios from "axios";

export default class RestInterface {
	constructor() {
		this.rest_library = axios;
	}

	async get(url, args = {}) {
		let params = {};
		if (args.headers) {
			params.headers = args.headers;
		}
		if (args.body_params) params.params = args.body_params;

		const response = await this.rest_library.get(url, params);

		return response.data;
	}

	async post(url, args = {}) {
		let body_params;
		let config = {};
		if (args.files) {
			body_params = new FormData();
			config.headers = {
				"Content-Type": "multipart/form-data"
			};
			for (var index in args.files) {
				body_params.append(index, args.files[index], args.files[index].name);
			}
			//If a post request with files and params
			if (args.params) {
				for (let key in args.params) {
					body_params.append(key, args.params[key]);
				}
			}
		} else if (args.params) {
			body_params = args.params;
		}

		if (args.query_params) {
			config = {
				params: args.query_params
			};
		}

		if (args.headers) {
			if (!config.headers) config.headers = {};

			for (let index in args.headers) {
				config.headers[index] = args.headers[index];
			}
		}
		const response = await this.rest_library.post(url, body_params, config);

		return response.data;
	}

	async put(url, args = {}) {
		let params = {};
		let config = {};
		if (args && args.params) {
			params = args.params;
		}
		if (args.headers) {
			config.headers = args.headers;
		}
		const response = await this.rest_library.put(url, params, config);
		return response.data;
	}

	async delete(url, args = {}) {
		let config = {};
		if (args && args.params) {
			config.params = args.params;
		}
		if (args.headers) {
			config.headers = args.headers;
		}
		const response = await this.rest_library.delete(url, config);
		return response.data;
	}
}
