import StoreInterfaceClass from "@src/shared/infrastructure/store-interface";

export default class {
	constructor() {
		this.store_interface = new StoreInterfaceClass();
	}

	async restore_store() {
		this.store_interface.delete_all_entities();
	}
}
