import StoreInterfaceClass from "@src/shared/infrastructure/store-interface.js";
import { encryptation_passphrase_store_entity } from "@config";
import moment from "moment";

export default class {
	constructor() {
		this.store_interface = new StoreInterfaceClass();
	}

	set_encryptation_passphrase(encryptation_passphrase) {
		this.store_interface.set(
			encryptation_passphrase_store_entity,
			encryptation_passphrase,
			moment().add(3, "days").toDate(),
			true
		);
	}

	get_encryptation_passphrase() {
		return this.store_interface.get(encryptation_passphrase_store_entity);
	}
}
