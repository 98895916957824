import { createRouter, createWebHistory } from "vue-router";

import Index from "@components/Index.vue";

import Historical from "@components/historical/Historical";
import Vym from "@components/vym/Vym.vue";

const routes = [
	{
		path: "/",
		name: "app",
		default: true, // indicate if it's the default route
		component: Index,
		children: [
			{
				path: "/assigments-historical",
				name: "assigments-historical",
				component: Historical
			},
			{
				path: "/vym",
				name: "vym",
				component: Vym
			}
		]
	}
];

const router = createRouter({
	// mode: 'history',
	history: createWebHistory(),
	routes
});

export default router;
