export default class {
	get_assigments() {
		return [
			{
				id: 1,
				value: "Presidencia",
				slug: "presidency"
			},
			{
				id: 2,
				value: "Tesoros",
				slug: "treasures"
			},
			{
				id: 3,
				value: "Perlas",
				slug: "pearls"
			},
			{
				id: 8,
				value: "Seamos",
				slug: "better_teachers"
			},
			{
				id: 4,
				value: "Vida cristiana",
				slug: "christian_life"
			},
			{
				id: 5,
				value: "Libro",
				slug: "book"
			},
			{
				id: 6,
				value: "Lectura libro",
				slug: "book_reading"
			},
			{
				id: 7,
				value: "Oración",
				slug: "prayers"
			}
		];
	}

	get_assigment_by_slug(slug) {
		const assigments = this.get_assigments();
		return assigments.find((item) => item.slug == slug);
	}
}
